import React from 'react'
import Layout from '../components/layout';

import { Hero, Container } from 'react-bulma-components';
import Media from 'react-media';



class About extends React.Component {
    render(){
        return(
            <Layout title="About" hover='about'>
            <Media query="(max-width:900px)">
                    {matches =>
                    matches ? (
                        <div>
                        <section className="aboutHero"></section>
                        <section className="mobileAboutHero">
                <Container fluid className="mobilefirstSec">
                        <h1 className="firstH"><span>About Us</span></h1>
                        <span className="holdingHorz">
                            <hr className="secondHorz"/>
                            </span>
                        
                        <p className="mobilefirstP">Lebanon Poultry was founded in 1990 by Dr. Rida El Mays, DVM, a renowned reference in the ﬁeld in Lebanon.</p>
                        <p className="mobilesecondP">He graduated from Cairo University and started his career working as an on-site DVM as well as providing Veterinary services. After several years of experience with one of the ﬁrst producers of one day old layer chicks in Lebanon, he started his own company, LEBANON POULTRY, which today provides, One day old layer chicks, Veterinary consultancy, and distributor of a renowned poultry vaccines and medication.</p>
                        <p className="mobilethirdP">Being present in the Lebanese market since 1990, Lebanon Poultry managed to maintain a high level of consistency, professionalism, and a client driven approach. With the mentioned aspects being at the core of the company, it has acquired a strong reputation in the poultry industry selling high quality 1 day old layer chicks, in addition to distributing the renowned HIPRA Vaccine and CHEMVET Veterinary products, and daily veterinary consultancy services.</p>
                    </Container>
                    <Container fluid className="mobilesecondSec">
                   
                        <h1 className="firstH"><span>Executives</span> </h1>
                        <span className="holdingHorz">
                            <hr className="secondHorz"/>
                            </span>
                        
                        <ul className="mobiletheList">
                            <li className="lItem">Rida El Mays DVM</li>
                            <li className="lItem">Founder/ Chief executive officer</li>
                            <li className="lItem">Walid El Mays DVM</li>
                            <li className="lItem">Chief operating officer</li>
                            <li className="lItem">Ali El Mays</li>
                            <li className="lItem">Chief ﬁnancial officer</li>
                        </ul>
                    </Container>
                    <Container fluid className="mobilesecondSec">
                   
                        <h1 className="firstH"><span>Product Distribution</span> </h1>
                        <span className="holdingHorz">
                            <hr className="secondHorz"/>
                            </span>
                        <ul className="mobiletheList2">
                            <li className="lItem">Representatives of the Dutch Bovans white and brown</li>

                            <li className="lItem">Exclusive distributors of the Jordanian CHEMVET</li>
                            <li className="lItem">Agrochemical & Veterinary Products.</li>
                            
                        </ul>
                    </Container>
                    <Container fluid className="mobilesecondSec">
                   
                        <h1 className="firstH"><span>Establishment Departments</span> </h1>
                        <span className="holdingHorz3">
                            <hr className="secondHorz"/>
                            </span>
                        <ul className="mobiletheList2">
                            <li className="lItem">Layer breeder farms.</li>
                            <li className="lItem">Hatchery.</li>
                            <li className="lItem">Commercial layers farms.</li>
                            <li className="lItem">Feed Mill.</li>
                            <li className="lItem">Veterinary clinic.</li>
                            <li className="lItem">Sales department of four sales officers covering all ﬁve governorate of Lebanon.</li>
                        </ul>
                    </Container>
                   
                </section>
                </div>
            ) : (
                <section className="aboutHero">
                <Container fluid className="mobilefirstSec">
                        <h1 className="firstH"><span>About Us</span><hr className="firstHorz"/> </h1>
                        
                        <p className="mobilefirstP">Lebanon Poultry was founded in 1990 by Dr. Rida El Mays, DVM, a renowned reference in the ﬁeld in Lebanon.</p>
                        <p className="mobilesecondP">He graduated from Cairo University and started his career working as an on-site DVM as well as providing Veterinary services. After several years of experience with one of the ﬁrst producers of one day old layer chicks in Lebanon, he started his own company, LEBANON POULTRY, which today provides, One day old layer chicks, Veterinary consultancy, and distributor of a renowned poultry vaccines and medication.</p>
                        <p className="mobilethirdP">Being present in the Lebanese market since 1990, Lebanon Poultry managed to maintain a high level of consistency, professionalism, and a client driven approach. With the mentioned aspects being at the core of the company, it has acquired a strong reputation in the poultry industry selling high quality 1 day old layer chicks, in addition to distributing the renowned HIPRA Vaccine and CHEMVET Veterinary products, and daily veterinary consultancy services.</p>
                    </Container>
                    <Container fluid className="mobilesecondSec">
                   
                        <h1 className="firstH"><span>Executives</span> </h1>
                        <span className="holdingHorz">
                            <hr className="secondHorz"/>
                            </span>
                        
                        <ul className="mobiletheList">
                            <li className="lItem">Rida El Mays DVM</li>
                            <li className="lItem">Founder/ Chief executive officer</li>
                            <li className="lItem">Walid El Mays DVM</li>
                            <li className="lItem">Chief operating officer</li>
                            <li className="lItem">Ali El Mays</li>
                            <li className="lItem">Chief ﬁnancial officer</li>
                        </ul>
                    </Container>
                    <Container fluid className="mobilesecondSec">
                   
                        <h1 className="firstH"><span>Product Distribution</span> </h1>
                        <span className="holdingHorz2">
                            <hr className="secondHorz"/>
                            </span>
                        <ul className="mobiletheList2">
                            <li className="lItem">Representatives of the Dutch Bovans white and brown</li>

                            <li className="lItem">Exclusive distributors of the Jordanian CHEMVET</li>
                            <li className="lItem">Agrochemical & Veterinary Products.</li>
                            
                        </ul>
                    </Container>
                    <Container fluid className="mobilesecondSec">
                   
                        <h1 className="firstH"><span>Establishment Departments</span> </h1>
                        <span className="holdingHorz3">
                            <hr className="secondHorz"/>
                            </span>
                        <ul className="mobiletheList2">
                            <li className="lItem">Layer breeder farms.</li>
                            <li className="lItem">Hatchery.</li>
                            <li className="lItem">Commercial layers farms.</li>
                            <li className="lItem">Feed Mill.</li>
                            <li className="lItem">Veterinary clinic.</li>
                            <li className="lItem">Sales department of four sales officers covering all ﬁve governorate of Lebanon.</li>
                        </ul>
                    </Container>
                   
                </section>
                            
            )
          }
        </Media>
                
                    
                        
                
                
            </Layout>
        );
    }
}
export default About